// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colors-js": () => import("./../src/pages/colors.js" /* webpackChunkName: "component---src-pages-colors-js" */),
  "component---src-pages-graphic-elements-js": () => import("./../src/pages/graphic-elements.js" /* webpackChunkName: "component---src-pages-graphic-elements-js" */),
  "component---src-pages-images-js": () => import("./../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logos-js": () => import("./../src/pages/logos.js" /* webpackChunkName: "component---src-pages-logos-js" */),
  "component---src-pages-stationary-js": () => import("./../src/pages/stationary.js" /* webpackChunkName: "component---src-pages-stationary-js" */),
  "component---src-pages-typography-js": () => import("./../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */)
}

